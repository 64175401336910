import { observer } from "mobx-react-lite";
import { useStore } from "../stores";
import { ActivityCard } from "./ActivityCard";

export const ActivitiesList = observer(() => {
  const { activityStore } = useStore();
  return (
    <div id="activities-list" className="flex flex-1 flex-col gap-4">
      {activityStore.availableActivities.map((activity) => (
        <ActivityCard
          key={activity.id}
          activity={activity}
          groupMembers={activityStore.group}
        />
      ))}
    </div>
  );
});
