import { TuneRounded } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../stores";
import { ActivityCategory } from "../types";

export const ActivitiesFilter = observer(() => {
  const { activityStore } = useStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const {
    filterSettings: { categories, onlyAvailable },
  } = activityStore;

  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const toggleStringInArray = (arr: string[], str: string) => {
    const set = new Set(arr);
    set.has(str) ? set.delete(str) : set.add(str);
    return [...set];
  };

  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <IconButton onClick={handleClick}>
        <TuneRounded color="inherit" />
      </IconButton>
      <Popover
        id="filter-menu"
        aria-labelledby="filter-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="p-4">
          <Typography variant="subtitle2">{t("categories")}</Typography>

          <FormGroup>
            {Object.values(ActivityCategory).map((category) => (
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked
                    checked={categories.includes(category)}
                    onChange={() =>
                      activityStore.updateFilterSettings({
                        key: "categories",
                        value: toggleStringInArray(categories, category),
                      })
                    }
                  />
                }
                label={t(category)}
              />
            ))}
          </FormGroup>

          <Typography variant="subtitle2" className="mt-4">
            Weitere Filter
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyAvailable}
                  onChange={() =>
                    activityStore.updateFilterSettings({
                      key: "onlyAvailable",
                      value: !activityStore.filterSettings.onlyAvailable,
                    })
                  }
                />
              }
              label="Nur verfügbare"
            />
          </FormGroup>
        </div>
      </Popover>
    </>
  );
});
