import { Chip } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterModel,
} from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "../stores";

const PAGE_SIZE = 20;

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    filterable: false,
    headerClassName: "font-bold",
  },
  { field: "activity", headerName: "Aktivität", width: 200, filterable: false },
  { field: "name", headerName: "Wer", width: 150, filterable: false },
  { field: "email", headerName: "E-Mail", width: 250 },
  { field: "selectedDate", headerName: "Start", width: 150 },
  {
    field: "scanned",
    headerName: "Gescanned",
    width: 150,

    renderCell: (params: GridCellParams) => (
      <Chip
        size="small"
        label={params.value ? "Ja" : "Nein"}
        color={params.value ? "success" : "error"}
      />
    ),
  },
];

export const Reservations = observer(() => {
  const { reservationStore } = useStore();

  const { totalReservations } = reservationStore;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [queryOptions, setQueryOptions] = useState<GridFilterModel>();

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    setQueryOptions(filterModel);
  }, []);

  useEffect(() => {
    reservationStore.fetchReservations();
  }, []);

  const rows = reservationStore
    .getReservations(paginationModel.page)
    .map((reservation) => ({
      id: reservation.id.slice(0, 6),
      activity: reservation.activitySnapshot.name,
      name: reservation.visitorName,
      email: reservation.visitorEmail,
      selectedDate: moment(reservation.selectedDate).format(
        "DD.MM.YYYY" +
          " " +
          moment(reservation.activitySnapshot.slot.start).format("HH:mm")
      ),
      scanned: reservation.scanned,
    }));

  //   const onFilterChange = (filterModel: GridFilterModel) => {
  //     console.log(filterModel);
  //     reservationStore.fetchReservations({
  //       filter: {
  //         path: ["slot", filterModel.items[0].field],
  //         contains: filterModel.items[0].value,
  //       },
  //     });
  //   };

  const hasValidFilter = queryOptions && queryOptions?.items[0].value !== "";

  useEffect(() => {
    reservationStore.fetchReservations({
      page: paginationModel.page,
      skip: paginationModel.pageSize * paginationModel.page,
      ...(hasValidFilter && {
        filter: {
          // path: ["slot", queryOptions.items[0].field],
          path: queryOptions.items[0].field,
          contains: queryOptions.items[0].value,
        },
      }),
    });
  }, [paginationModel, queryOptions]);

  //   const [rowCountState, setRowCountState] = useState(totalReservations || 0,);
  // useEffect(() => {
  //   setRowCountState((prevRowCountState: any) =>
  //     rowCount !== undefined ? rowCount : prevRowCountState,
  //   );
  // }, [rowCount, setRowCountState]);

  const [rowCountState, setRowCountState] = useState(totalReservations);
  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      totalReservations !== undefined ? totalReservations : prevRowCountState
    );
  }, [totalReservations, setRowCountState]);

  return (
    <div className="flex h-full min-h-0 justify-center p-8">
      <div style={{}}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: PAGE_SIZE },
            },
          }}
          // pageSizeOptions={[PAGE_SIZE]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          rowCount={rowCountState}
          loading={reservationStore.isReservationsLoading}
          autoHeight
          filterMode="server"
          onFilterModelChange={onFilterChange}
          // getRowHeight={() => "auto"}

          // checkboxSelection
        />
      </div>
    </div>
  );
});
