import { ExpandLess, ExpandMore } from "@mui/icons-material";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useState } from "react";
import { ReadMoreText } from ".";
import Logo from "../assets/images/logo.svg";
import { useStore } from "../stores";
import { Activity, GroupMember } from "../types";

type Props = {
  activity: Activity;

  groupMembers: GroupMember[];
};

export const ActivityCard = observer((props: Props) => {
  const { activity, groupMembers } = props;
  const { activityStore } = useStore();
  const [expanded, setExpanded] = useState(false);

  const filterSlotsByCapacity = (slots: Activity["slots"]) => {
    return slots.filter(({ available }) => {
      if (available !== undefined && activity.isGroupActivity) {
        return available > 0;
      } else if (available !== undefined) {
        return (
          available >= activityStore.countSelectedParticipants(activity.id)
        );
      } else {
        return true;
      }
    });
  };

  const firstSlots = filterSlotsByCapacity(activity.slots).slice(0, 6);
  const lastSlots = filterSlotsByCapacity(activity.slots).slice(6);

  const renderSlots = (slots: Activity["slots"]) => {
    return slots.map((slot) => {
      const selected = activityStore.slotSelected(activity.id, slot.id);
      const handleClick = () => {
        if (selected) {
          activityStore.deselectSlot(activity.id);
        } else {
          activityStore.selectSlot(activity.id, slot.id);
        }
      };
      return (
        <Button
          key={slot.id + slot.start}
          variant="contained"
          size="small"
          disableElevation
          className={`${selected ? "" : "bg-slate-500"}`}
          onClick={handleClick}
        >
          {moment(slot.start).format("HH:mm")}
        </Button>
      );
    });
  };

  const renderExternalBookingContent = (href: string) => (
    // @ts-ignore because target is working but missing in spec
    <Button
      href={href}
      target="_blank"
      variant="contained"
      color="secondary"
      size="small"
      className="mt-2"
    >
      Zum Buchen hier klicken
    </Button>
  );

  const renderBookableContent = () => (
    <>
      {/* People */}
      {!activity.isGroupActivity && (
        <div className="flex gap-x-1.5 flex-wrap">
          {groupMembers.map((member) => {
            const checked = activityStore.participantSelected(
              activity.id,
              member.id
            );

            const handleClick = () => {
              if (checked) {
                activityStore.deselectParticipant(activity.id, member.id);
              } else {
                activityStore.selectParticipant(activity.id, member.id);
              }
            };
            return (
              <FormControlLabel
                key={member.name}
                control={<Checkbox checked={checked} onClick={handleClick} />}
                label={member.name}
              />
            );
          })}
        </div>
      )}
      {/* Times */}
      <div className="flex gap-1.5 flex-wrap flex-col">
        {firstSlots.length === 0 && (
          <Typography variant="subtitle2" className="text-center">
            Keine freien Plätze
          </Typography>
        )}
        {firstSlots.length > 0 && (
          <div className="flex flex-1 flex-row gap-1.5 relative justify-between items-center">
            {!activity.isGroupActivity &&
              !activityStore.hasParticipantsSelected(activity.id) && (
                <div className="absolute top-0 left-0 w-full h-full bg-slate-200  opacity-70 z-50 flex items-center justify-center">
                  <Typography
                    variant="subtitle2"
                    className="text-center text-black"
                  >
                    Bitte zuerst Personen auswählen
                  </Typography>
                </div>
              )}

            <div className="flex gap-1.5 self-start flex-wrap">
              {renderSlots(firstSlots)}
              {expanded && renderSlots(lastSlots)}
            </div>
            {lastSlots.length > 0 && (
              <IconButton
                className="self-start -mt-1"
                size="small"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </div>
        )}
      </div>
    </>
  );
  return (
    <div className="flex flex-col border border-slate-300 border-solid rounded-lg">
      {/* Main */}
      <div className="flex flex-wrap">
        {/* Image */}
        <div className="flex max-h-48 sm:max-h-none  w-full sm:max-w-[160px]">
          <img
            src={activity.previewImageUrl || Logo}
            alt="Preview"
            // width={166}
            //height={200}
            className="object-cover rounded-t-lg sm:rounded-tl-lg w-full sm:max-w-[160px]"
          />
        </div>
        {/* Content */}
        <div className=" flex flex-col  flex-1 px-4 py-4 ">
          {/* Title */}
          <div className="flex flex-1 justify-between">
            <div>
              <Typography variant="subtitle1">{activity.name}</Typography>
              <Typography variant="body2">by {activity.vendor}</Typography>
            </div>
          </div>
          <div className="my-2">
            <ReadMoreText text={activity.description} />
          </div>

          {!activity.hasOnlyExternalReservation && renderBookableContent()}
          {activity.hasOnlyExternalReservation &&
            activity.externalReservationLink &&
            renderExternalBookingContent(activity.externalReservationLink)}
        </div>
      </div>

      {/* Footer */}
      <div className="flex justify-end px-4 py-1 bg-slate-100 rounded-b-lg min-h-[30px]">
        {activity.duration > 0 && (
          <div className="flex items-center">
            <TimerOutlinedIcon className="mr-1 text-sm" />
            <Typography variant="subtitle2">{activity.duration} min</Typography>
          </div>
        )}

        {typeof activity.basePrice === "number" && (
          <>
            <div className="mx-2 w-0.5 bg-slate-300 border-full" />
            <Typography variant="subtitle2">{activity.basePrice} €</Typography>
          </>
        )}
      </div>
    </div>
  );
});
