import { Typography } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";

export const ReadMoreText = ({ text }: { text: string }) => {
  //   const [clamped, setClamped] = React.useState(true);
  //   const [showButton, setShowButton] = React.useState(true);
  //   const containerRef = React.useRef(null);
  //   const handleClick = () => setClamped(!clamped);

  //   React.useEffect(() => {
  //     const hasClamping = (el: any) => {
  //       const { clientHeight, scrollHeight } = el;
  //       return clientHeight !== scrollHeight;
  //     };

  //     const checkButtonAvailability = () => {
  //       if (containerRef.current) {
  //         // Save current state to reapply later if necessary.
  //         const hadClampClass = clamped;
  //         // Make sure that CSS clamping is applied if aplicable.
  //         //  if (!hadClampClass) containerRef.current.classList.add("clamp");
  //         // Check for clamping and show or hide button accordingly.
  //         setShowButton(hasClamping(containerRef.current));
  //         // Sync clamping with local state.
  //         // if (!hadClampClass) containerRef.current.classList.remove("clamp");
  //       }
  //     };

  //     const debouncedCheck = _.debounce(checkButtonAvailability, 50);

  //     checkButtonAvailability();
  //     window.addEventListener("resize", debouncedCheck);

  //     return () => {
  //       window.removeEventListener("resize", debouncedCheck);
  //     };
  //   }, [containerRef]);

  //   console.log("clamped", clamped);

  //   return (
  //     <>
  //       <div
  //         ref={containerRef}
  //         className={clamped ? "line-clamp-1 break-words" : ""}
  //       >
  //         {text}
  //       </div>
  //       {showButton && (
  //         <button onClick={handleClick}>Read {clamped ? "more" : "less"}</button>
  //       )}
  //     </>
  //   );

  const ref = React.useRef(null);
  const { isTruncated, isShowingMore, toggleIsShowingMore } =
    useTruncatedElement({
      ref,
    });

  // Code to get your note content...

  return (
    <div>
      <p
        ref={ref}
        className={`m-0 break-words ${!isShowingMore && "line-clamp-2"}`}
      >
        {text}
      </p>
      {isTruncated && (
        <Typography
          // variant="text"
          color="primary"
          onClick={toggleIsShowingMore}
          className="mt-0 px-0 cursor-pointer"
        >
          {isShowingMore ? "zeige weniger" : "zeige mehr"}
        </Typography>
      )}
    </div>
  );
};

const useTruncatedElement = ({ ref }: Record<any, any>) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [isShowingMore, setIsShowingMore] = useState(false);

  useLayoutEffect(() => {
    const { offsetHeight, scrollHeight } = ref.current || {};

    if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, [ref]);

  const toggleIsShowingMore = () => setIsShowingMore((prev) => !prev);

  return {
    isTruncated,
    isShowingMore,
    toggleIsShowingMore,
  };
};
