import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Event } from "../types";
import { convertToRgba } from "../utils";

interface ProcessedEvent extends Event {
  top: number;
  height: number;
  leftOffset: string | number;
  width: number;
  numOverlapping: number;
}
export const Calendar = observer(
  ({ events, parentHeight }: { events: Event[]; parentHeight?: number }) => {
    const intervalHeight = 50;

    const renderHours = () => {
      const hours = [];
      for (let i = 8; i < 23; i++) {
        hours.push(
          <div key={i} style={{ height: intervalHeight }}>
            <div className="absolute left-0 right-0 flex flex-row items-center">
              <div className="text-gray-400 w-10">{i}:00</div>
              <div className="ml-2 h-px bg-gray-300 text-sm w-full" />
            </div>
          </div>
        );
      }
      return hours;
    };

    function timeToDecimal(timeString: string) {
      const [hours, minutes] = timeString.split(":").map(Number);
      return hours + minutes / 60;
    }

    const checkOverlap = (event: Event, eventsToCheck: Event[]) => {
      let overlapOrder = 0;
      let count = 0;
      let overlaps = [];

      for (let otherEvent of eventsToCheck) {
        const otherStart = timeToDecimal(otherEvent.start);
        const otherEnd = timeToDecimal(otherEvent.end);
        const eventStart = timeToDecimal(event.start);
        const eventEnd = timeToDecimal(event.end);

        if (
          (eventStart >= otherStart && eventStart < otherEnd) ||
          (eventEnd > otherStart && eventEnd <= otherEnd) ||
          (eventStart < otherStart && eventEnd > otherEnd)
        ) {
          overlaps.push(otherEvent);
          count = count + 1;
        }
      }

      // We also need to add the current event to the overlaps to compare the order
      if (count !== 0) {
        overlaps.push(event);
      }

      // Sort overlapping events by start time
      overlapOrder = overlaps
        .sort((a, b) => b.addedAt.valueOf() - a.addedAt.valueOf())
        .findIndex((e) => e.id === event.id);

      return { count: overlaps.length, overlapOrder };
    };

    const renderEvents = () => {
      const processedEvents: ProcessedEvent[] = [];
      events.map((event, index) => {
        const { count: numOverlapping, overlapOrder } = checkOverlap(
          event,
          events.filter((e) => e.id !== event.id)
        );
        const eventHeight =
          (timeToDecimal(event.end) - timeToDecimal(event.start)) *
          intervalHeight;

        const eventTop = (timeToDecimal(event.start) - 8) * intervalHeight + 12;

        let eventWidth = 0;
        let leftOffset;
        if (numOverlapping === 0) {
          eventWidth = 100;
          leftOffset = "0px";
        } else {
          eventWidth = 100 / numOverlapping;
          leftOffset = (eventWidth * overlapOrder).toString() + "%";
        }

        processedEvents.push({
          ...event,
          top: eventTop,
          height: eventHeight,
          leftOffset: leftOffset,
          width: eventWidth,
          numOverlapping,
          color: event.color,
        });
      });

      return processedEvents.map((event, index) => (
        <div
          key={index}
          className="rounded absolute px-2"
          style={{
            height: `${event.height}px`,
            top: `${event.top}px`,
            left: event.leftOffset,
            width: `calc(${event.width}%`,
            //width: `calc(100% - ${event.leftOffset + 8}px)`,
            backgroundColor: convertToRgba(event.color, 0.4),
            color: event.color,
            borderLeft: `3px solid ${event.color}`,
            fontWeight: "700",
          }}
        >
          <Typography className="font-bold">{event.title}</Typography>

          <Typography variant="caption" className="font-bold">
            {event.participants.join(", ")}
          </Typography>
        </div>
      ));
    };

    return (
      <div className="w-full overflow-auto">
        <div className="container mx-auto">
          <div className="relative">
            {renderHours()}
            <div
              className="absolute left-0 right-0 top-0 bottom-0"
              style={{
                left: "calc(2.5rem + 8px)",
              }}
            >
              {renderEvents()}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
