import axios, { AxiosInstance } from "axios";
import { ReservationRequest } from "../models";
import { RootStore } from "../stores";
import {
  ApiParams,
  ExtraTicket,
  Reservation,
  ReservationGroup,
} from "../types";

export class ApiService {
  rootStore: RootStore;
  client: AxiosInstance;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.client = axios.create({
      baseURL: window._env_.API_HOST,
    });

    this.createInterceptors();
  }

  createInterceptors() {
    this.client.interceptors.request.use(
      (config) => {
        const token = this.rootStore.stores.authStore.token;
        if (token) {
          config!.headers!.Authorization = `Bearer ${this.rootStore.stores.authStore.token}`;
        }
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        return Promise.resolve(error.response);
      }
    );
  }

  async getActivities(date: string) {
    return this.client({
      method: "get",
      url: "/activity",
      params: {
        date,
      },
    });
  }

  async verifyReservation(reservationId: string) {
    return this.client({
      method: "post",
      url: `/reservation/verify/${reservationId}`,
    });
  }

  async createReservations(reservations: ReservationRequest) {
    return this.client({
      method: "post",
      url: "/reservation",
      data: reservations,
    });
  }

  async confirmReservations(reservationGroupId: string) {
    return this.client({
      method: "post",
      url: `/reservation/group/${reservationGroupId}/confirm`,
    });
  }

  async getReservation(reservationId: string) {
    return this.client({
      method: "get",
      url: `/reservation/${reservationId}`,
    });
  }

  async updateReservation(reservationId: string, update: Partial<Reservation>) {
    return this.client({
      method: "patch",
      url: `/reservation/${reservationId}`,
      data: update,
    });
  }

  async updateReservationGroup(
    reservationGroupId: string,
    update: Partial<ReservationGroup>
  ) {
    return this.client({
      method: "patch",
      url: `/reservation/group/${reservationGroupId}`,
      data: update,
    });
  }

  async getReservationGroup(reservationGroupId: string) {
    return this.client({
      method: "get",
      url: `/reservation/group/${reservationGroupId}`,
    });
  }

  async deleteReservation(reservationId: string) {
    return this.client({
      method: "delete",
      url: `/reservation/${reservationId}`,
    });
  }

  async getReservations({ skip, take = 20, filter }: ApiParams) {
    return this.client({
      method: "get",
      url: "/reservation",
      params: {
        skip,
        take,
        ...(filter && {
          path: filter.path,
          contains: filter.contains,
        }),
      },
    });
  }

  async postCheckout(
    reservationGroupId: string,
    date: string,
    ticketAmount: number,
    extraTickets: ExtraTicket[] = []
  ) {
    return this.client({
      method: "post",
      url: "/checkout",
      data: {
        reservationGroupId,
        date,
        ticketAmount,
        extraTickets,
      },
    });
  }

  async postCheckoutWithoutAdmission(
    reservationGroupId: string,
    extraTickets: ExtraTicket[]
  ) {
    return this.client({
      method: "post",
      url: "/checkout/without-admission",
      data: {
        reservationGroupId,
        extraTickets,
      },
    });
  }
}
