import { Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

export const CancelReservationSuccess = observer(() => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-1 flex-col justify-center items-center p-4">
      <Typography variant="h1" className="text-center">
        Deine Buchung wurde storniert!
      </Typography>
      <Typography variant="body1" className="text-center my-4">
        Du erhältst die Buchungsbestätigung auch noch einmal per E-Mail.
      </Typography>
      <Button variant="outlined" onClick={() => navigate("/")}>
        Neue Buchung
      </Button>
    </div>
  );
});
