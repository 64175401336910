import { ChevronLeftRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Link,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import { useStore } from "../stores";

export const Cart = observer(() => {
  const { activityStore, admissionStore } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [paymentOption, setPaymentOption] = useState<"now" | "later">("now");
  const [checkoutError, setCheckoutError] = useState<string>("");
  const { t } = useTranslation();

  const { selectedDayIsOnWeekend } = activityStore;

  const handleReservation = async () => {
    setLoading(true);

    const { status, data } = await activityStore.createReservations();

    if (status !== 201) {
      setLoading(false);
      setCheckoutError(t("checkoutError"));
      return;
    }

    if (selectedDayIsOnWeekend && paymentOption === "now") {
      const extraTickets = activitiesWithUpfrontPayment.map((selected) => {
        const activity = activityStore.getActivity(selected.id);
        if (!activity)
          throw new Error("Activity not found in price calculation.");
        return {
          eventId: activity.sourceData?.attributes?.vivenuEventId,
          ticketId: activity.sourceData?.attributes?.vivenuTicketId,
          amount: selected.participants.length,
        };
      });
      await admissionStore.createCheckout(
        data.id,
        activityStore.selectedDate.toISOString().split("T")[0] + "T00:00:00Z",
        activityStore.group.length,
        extraTickets
      );

      admissionStore.setTicketModalOpen(true);
    } else if (activityStore.hasSelectedActivityWithUpfrontPayment) {
      const extraTickets = activitiesWithUpfrontPayment.map((selected) => {
        const activity = activityStore.getActivity(selected.id);
        if (!activity)
          throw new Error("Activity not found in price calculation.");
        return {
          eventId: activity.sourceData?.attributes?.vivenuEventId,
          ticketId: activity.sourceData?.attributes?.vivenuTicketId,
          amount: activity.isGroupActivity ? 1 : selected.participants.length,
        };
      });

      await admissionStore.createCheckoutWithoutAdminssion(
        data.id,
        extraTickets
      );

      admissionStore.setTicketModalOpen(true);
    } else {
      await activityStore.confirmReservations(data.id);
      navigate("/success");
    }
    setLoading(false);
  };

  const price = activityStore.selectedActivities.reduce((p, a) => {
    const activity = activityStore.getActivity(a.id);
    if (!activity) throw new Error("Activity not found in price calculation.");

    const basePrice = activity.basePrice || 0;
    return (
      p +
      (activity.isGroupActivity ? basePrice : basePrice * a.participants.length)
    );
  }, 0);

  const admissionFee = !selectedDayIsOnWeekend
    ? 0
    : 5 * activityStore.group.length;

  const activitiesWithUpfrontPayment = activityStore.selectedActivities.filter(
    (selected) => {
      const activity = activityStore.getActivity(selected.id);

      if (!activity || !activity.sourceData) return false;
      if (
        activity.sourceData.attributes?.requireUpfrontPayment &&
        !!activity.sourceData.attributes?.vivenuTicketId &&
        !!activity.sourceData.attributes?.vivenuEventId
      )
        return true;

      return false;
    }
  );

  const priceOfActivitiesWithUpfrontPayment =
    activitiesWithUpfrontPayment.reduce(
      (p, a) => {
        const activity = activityStore.getActivity(a.id);
        if (!activity)
          throw new Error("Activity not found in price calculation.");
        // if group activity, only add base price once
        if (activity.isGroupActivity) {
          return p + activity.basePrice;
        } else return activity.basePrice * a.participants.length;
      },

      0
    );

  return (
    <div className="flex flex-col flex-1 items-center">
      <div className="flex justify-center">
        <Typography variant="h1">Endspurt</Typography>
      </div>

      <div className="flex flex-auto mt-8 flex-wrap gap-y-6 gap-x-6">
        <div className="w-full md:w-1/2">
          <div className="flex flex-col mb-4 gap-y-3">
            {activityStore.selectedActivitiesWithActivityAndSlot.map(
              (selected) => {
                const { activity, slot } = selected;
                return (
                  <div className="flex flex-col border border-slate-300 border-solid rounded-lg">
                    {/* Main */}
                    <div className="flex">
                      {/* Image */}
                      <div className="flex flex-1">
                        <img
                          src={activity.previewImageUrl || Logo}
                          alt="Preview"
                          width={120}
                          height={150}
                          style={{
                            objectFit: activity.previewImageUrl
                              ? "cover"
                              : "contain",
                          }}
                          className="object-cover rounded-tl-lg"
                        />
                      </div>
                      {/* Content */}
                      <div className="pr-4 pl-8 pt-4 w-full">
                        {/* Title */}
                        <div className="flex flex-1 justify-between">
                          <div>
                            <Typography variant="subtitle1">
                              {activity.name}
                            </Typography>
                            <Typography>by {activity.vendor}</Typography>
                          </div>
                        </div>
                        {/* Times */}
                        <div className="flex flex-wrap my-2">
                          <Typography variant="subtitle2">
                            {slot.start + " - " + slot.end}
                          </Typography>
                        </div>
                        {/* People */}
                        <div className="flex gap-x-1.5 flex-wrap">
                          {activity.isGroupActivity && (
                            <Typography variant="subtitle2">
                              Gruppenbuchung
                            </Typography>
                          )}
                          {activityStore
                            .activityParticipants(selected.participants)
                            .map((member) => {
                              return (
                                <FormControlLabel
                                  key={member.name}
                                  control={<Checkbox checked size="small" />}
                                  label={member.name}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    {/* Footer */}
                    <div className="flex justify-end px-8 py-2 bg-slate-100 rounded-b-lg min-h-[30px]">
                      {typeof activity.basePrice === "number" && (
                        <Typography variant="subtitle2">
                          {activity.basePrice} €
                        </Typography>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <Button
            variant="outlined"
            size="large"
            startIcon={<ChevronLeftRounded />}
            onClick={() => navigate("/")}
          >
            <Typography>Zurück zur Auswahl</Typography>
          </Button>
        </div>
        <div className="flex flex-col flex-1 md:w-1/2 ">
          <Typography variant="h2" className="mb-1">
            Zusammenfassung
          </Typography>
          <Typography variant="body1">
            Hier findest du alle wichtigen Informationen zu deinen Erlebnissen
            und deinem Besuch im HAPPY FRANKY.
          </Typography>
          {/* <Divider className="my-4" /> */}
          <div className="flex flex-row gap-x-6 mt-4">
            {/* <div className="">
              <Typography variant="subtitle1">Datum</Typography>
              <Typography variant="body1">
                {activityStore.selectedDate?.format("DD.MM.YYYY")}
              </Typography>
            </div>
            <div className="">
              <Typography variant="subtitle1">Aktiväten</Typography>
              <Typography variant="body1">
                {activityStore.selectedDate?.format("DD.MM.YYYY")}
              </Typography>
            </div> */}
            <div className="">
              <Typography variant="subtitle1">Datum</Typography>
              <Typography variant="body1">
                {activityStore.selectedDate?.format("DD.MM.YYYY")}
              </Typography>
            </div>
          </div>

          <Divider className="my-4" />
          <div>
            <Typography variant="subtitle1">Einzelheiten zum Preis</Typography>
            <div className="flex flex-row justify-between my-2">
              <Typography variant="body1">Gesamtpreis der Aktiväten</Typography>
              <Typography variant="body1">{price + " €"}</Typography>
            </div>

            <div className="flex flex-row justify-between">
              <Typography variant="body1">
                {selectedDayIsOnWeekend ? 5 : 0} € Eintrittspreis x{" "}
                {activityStore.group.length} Personen
              </Typography>
              <Typography variant="body1">{admissionFee + " €"}</Typography>
            </div>

            <Divider className="my-2" />
            <div className="flex flex-row justify-between">
              <Typography variant="body1">Gesamtbetrag</Typography>
              <Typography variant="body1">{price + admissionFee} €</Typography>
            </div>

            <Divider className="my-2" />
            <div className="flex flex-row justify-between">
              <Typography variant="body1" className="font-bold">
                Heute zu zahlender Betrag
              </Typography>
              <Typography variant="body1" className="font-bold">
                {(selectedDayIsOnWeekend && paymentOption === "now"
                  ? 5 * activityStore.group.length
                  : 0) +
                  priceOfActivitiesWithUpfrontPayment +
                  " €"}
              </Typography>
            </div>

            {selectedDayIsOnWeekend && (
              <>
                <Alert severity="info" className="mt-4">
                  Deine Erlebnisse bezahlst du erst vor Ort bei unseren
                  Mitunternehmern. Bezahl aber heute schon den Eintritt und
                  vermeide langes Anstehen am Eingang.
                </Alert>
                <div className="mt-4">
                  <div>
                    <div
                      aria-label="list"
                      onClick={() => setPaymentOption("now")}
                      className={`${
                        paymentOption === "now"
                          ? "border-slate-600"
                          : "border-slate-200 border-b-0"
                      } border-solid border rounded-t-lg p-4 flex items-start justify-between`}
                      // className={`${"border-slate-200"} border-solid border rounded-t-lg p-4`}
                    >
                      <div>
                        <Typography variant="body1" className="font-bold">
                          Eintritt jetzt bezahlen
                        </Typography>
                        <Typography variant="body1">
                          Zahle den Eintritt vorab, damit alles erledigt ist.
                        </Typography>
                      </div>
                      <Radio checked={paymentOption === "now"} size="small" />
                    </div>

                    <div
                      aria-label="quilt"
                      onClick={() => setPaymentOption("later")}
                      className={`${
                        paymentOption === "later"
                          ? "border-slate-600 border-t-1"
                          : "border-slate-200 border-t-0"
                      } border-solid border rounded-b-lg p-4 flex items-start justify-between`}
                    >
                      <div>
                        <Typography variant="body1" className="font-bold">
                          Eintritt später zahlen
                        </Typography>
                        <Typography variant="body1">
                          Du kannst die Eintrittstickets immer noch später oder
                          vor Ort kaufen.
                        </Typography>
                      </div>
                      <Radio checked={paymentOption === "later"} size="small" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <Divider className="my-4" />
          <div className="">
            <Typography variant="subtitle1">Was du wissen solltest</Typography>
            <Typography variant="body1">
              Für die von dir ausgewählten Aktivitäten gibt es keine besonderen
              Anforderungen. Komme bitte pünktlich und bereit, Spaß zu haben.
            </Typography>
          </div>
          <Divider className="my-4" />
          <div className="">
            <Typography variant="subtitle1">Deine Tickets</Typography>
            <Typography variant="body1">
              Für personalisierte QR Codes und Timetables hier E-Mail-Adressen
              eingeben.
            </Typography>
            <div className="flex flex-col gap-y-2 mt-4">
              {activityStore.group.map((member) => (
                <div className="flex flex-row items-center">
                  <Typography variant="body1" className="w-28">
                    {member.name}
                  </Typography>
                  <TextField
                    placeholder="E-Mail-Adresse"
                    size="small"
                    className="w-full max-w-sm"
                    value={member.email}
                    onChange={(e) =>
                      activityStore.changeGroupMemberEmail(
                        member.id,
                        e.target.value
                      )
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          <Divider className="my-4" />
          <div className="mb-8">
            <Typography variant="body1">
              Indem du den unten stehenden Button auswählst, erklärst du dich
              mit den{" "}
              <Link
                href="https://shop.happyfranky.de/agb"
                target="_blank"
                color={"primary"}
                underline="hover"
              >
                Allgemeinen Geschäftsbedingungen
              </Link>{" "}
              und der{" "}
              <Link
                href="https://www.happyfranky.de/datenschutz"
                target="_blank"
                color={"primary"}
                underline="hover"
              >
                Datenschutzrichtlinie
              </Link>{" "}
              von HAPPY FRANKY einverstanden.
            </Typography>
          </div>
          {checkoutError && (
            <Alert severity="error" className="mb-8">
              {checkoutError}
            </Alert>
          )}

          <LoadingButton
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleReservation}
            loading={loading}
            disabled={
              loading ||
              activityStore.selectedActivitiesWithActivityAndSlot.length ===
                0 ||
              !!checkoutError
            }
            className="mb-8"
          >
            <Typography>
              Bestätigen und{" "}
              {activitiesWithUpfrontPayment.length > 0 ||
              (selectedDayIsOnWeekend && paymentOption === "now")
                ? "bezahlen"
                : "reservieren"}
            </Typography>
          </LoadingButton>
        </div>
      </div>
    </div>
  );
});
