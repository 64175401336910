import { createContext, useContext } from "react";
import { ApiService } from "../services";
import { ActivityStore } from "./activity";
import { AdmissionStore } from "./admission";
import { AuthStore } from "./auth";
import { ReservationStore } from "./reservation";
import { UserStore } from "./user";

export class RootStore {
  stores: {
    userStore: UserStore;
    authStore: AuthStore;
    activityStore: ActivityStore;
    reservationStore: ReservationStore;
    admissionStore: AdmissionStore;
  };
  services: {
    api: ApiService;
  };

  constructor() {
    this.services = {
      api: new ApiService(this),
    };
    this.stores = {
      userStore: new UserStore(this),
      authStore: new AuthStore(this),
      activityStore: new ActivityStore(this),
      reservationStore: new ReservationStore(this),
      admissionStore: new AdmissionStore(this),
    };

    // if not production set the stores to window for debugging
    if (process.env.NODE_ENV !== "production") {
      console.log("setting stores to window");

      (window as any).stores = this.stores;
    }
  }
}

// export type TRootStore = RootStore;

/**
 * The root store will be provided to the Provider
 */
export const rootStore = new RootStore();

/**
 * Enables us to access the store as hook inside components
 */
export function useStore() {
  const store = useContext(StoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}

export const StoreContext = createContext<RootStore["stores"] | null>(null);

/**
 * Enables us to access the services as hook inside components
 */
export function useService() {
  const services = useContext(ServiceContext);
  if (services === null) {
    throw new Error("Services cannot be null, please add a context provider");
  }
  return services;
}

export const ServiceContext = createContext<RootStore["services"] | null>(null);
