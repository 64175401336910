export {};

declare global {
  interface Window {
    _env_: Record<string, any>;
  }
}

export type SelectedActivity = {
  id: string;
  slotId?: string;
  participants: string[];
  addedAt: number;
};

export type GroupMember = {
  id: string;
  name: string;
  email: string;
  color: string;
};

export interface Event {
  id: string;
  start: string;
  end: string;
  title: string;
  participants: string[];
  addedAt: number;
  color: string;
}

export interface ReservationRequest {
  activity: ReservationRequestActivity;
  selectedDate: string;
  visitorName: string;
  visitorEmail?: string;
}

export interface GenericActivity {
  id: string;
  source: string;
  vendor: string;
  vendorId: string;
  name: string;
  duration: number;
  description: string;
  basePrice: number;
  category: ActivityCategory;
  previewImageUrl: string | null;
  hasOnlyExternalReservation: boolean;
  externalReservationLink: string | null;
  isGroupActivity: boolean;
  sourceData?: Record<string, any>;
}

export enum ActivityCategory {
  ACTION = "action",
  WORKSHOP = "workshop",
  EVENT = "event",
  FOOD = "food",
  SPORT = "sport",
  HEALTH = "health",
}

export interface Activity extends GenericActivity {
  slots: Slot[];
}

export interface ReservationRequestActivity extends GenericActivity {
  slot: Slot;
}

export interface Slot {
  start: Date;
  id: string;
  available: number;
}

export interface Reservation {
  id: string;
  visitorName: string;
  visitorEmail: string;
  activityId: string;
  activitySource: string;
  slotId: string;
  activitySnapshot: Record<string, any>;
  vendorId: string;
  selectedDate: string;
  scanned: boolean;
  scannedAt: Date;
  reservationGroupId: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
}

export type ReservationGroup = {
  id: string;
  selectedDate: string;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  reservations: Reservation[];
  user: Record<string, any>;
  reminderSentAt: Date | null;
  transactionId: string;
};

export interface FilterParams {
  path: string[] | string;
  contains: string;
}

export interface ApiParams {
  skip?: number;
  take?: number;
  filter?: FilterParams;
}

export type ExtraTicket = {
  eventId: string;
  ticketId: string;
  amount: number;
};
