import { z } from "zod";

export const reservationRequestSchema = z.object({
  selectedDate: z
    .string()
    .regex(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/),
  reservations: z.array(
    z.object({
      activity: z.object({
        id: z.string(),
        source: z.string(),
        vendor: z.string(),
        vendorId: z.string(),
        name: z.string(),
        description: z.string(),
        duration: z.number(),
        slot: z.object({
          start: z.date(),
          id: z.string(),
          available: z.number(),
        }),
        externalReservationLink: z.string().nullable(),
        hasOnlyExternalReservation: z.boolean(),
        isGroupActivity: z.boolean(),
        basePrice: z.number(),
      }),
      visitorEmail: z.string().email().optional().or(z.literal("")),
      visitorName: z.string().optional().or(z.literal("")),
    })
  ),
});

export type ReservationRequest = z.infer<typeof reservationRequestSchema>;
