import { observer } from "mobx-react-lite";
import { ComponentType, FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../stores";

/**
 * @ignore
 */
const defaultOnRedirecting = (): JSX.Element => <></>;

/**
 * @ignore
 */
const defaultReturnTo = window.location.href;

/**
 * Options for the withAuthenticationRequired Higher Order Component
 */
export interface WithAuthenticationRequiredOptions {
  role?: string;
  /**
   * ```js
   * withAuthenticationRequired(Profile, {
   *   returnTo: '/profile'
   * })
   * ```
   *
   * or
   *
   * ```js
   * withAuthenticationRequired(Profile, {
   *   returnTo: () => window.location.hash.substr(1)
   * })
   * ```
   *
   * Add a path for the `onRedirectCallback` handler to return the user to after login.
   */
  returnTo?: string;
  /**
   * ```js
   * withAuthenticationRequired(Profile, {
   *   onRedirecting: () => <div>Redirecting you to the login...</div>
   * })
   * ```
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => JSX.Element;
  /**
   * ```js
   * withAuthenticationRequired(Profile, {
   *   loginOptions: {
   *     appState: {
   *       customProp: 'foo'
   *     }
   *   }
   * })
   * ```
   *
   * Pass additional login options, like extra `appState` to the login page.
   * This will be merged with the `returnTo` option used by the `onRedirectCallback` handler.
   */
  loginOptions?: Keycloak.KeycloakLoginOptions;
}

/**
 * ```js
 * const MyProtectedComponent = withAuthenticationRequired(MyComponent);
 * ```
 *
 * When you wrap your components in this Higher Order Component and an anonymous user visits your component
 * they will be redirected to the login page and returned to the page they we're redirected from after login.
 */
export const withAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {}
): FC<P> => {
  return observer((props: P) => {
    const {
      returnTo = defaultReturnTo,
      onRedirecting = defaultOnRedirecting,
      loginOptions,
      role,
    } = options;

    const {
      authStore: { client },
      activityStore,
    } = useStore();

    const navigate = useNavigate();

    const { authenticated } = client;

    useEffect(() => {
      if (!authenticated) {
        console.log("not authenticated");

        localStorage.setItem(
          "path",
          window.location.pathname + window.location.search
        );

        const opts: Keycloak.KeycloakLoginOptions = {
          ...loginOptions,
          redirectUri: window.location.origin + "/callback",
        };
        (async (): Promise<void> => {
          activityStore.saveCartToLocalStorage();
          await client.login(opts);
        })();
      }
    }, [authenticated]);

    // check if the user is allowed to access the route
    if (authenticated && role) {
      console.log("checking role");

      if (!client.hasRealmRole(role)) {
        console.log("not allowed");
        navigate("/unauthorized");
        return null;
      }
    }

    return authenticated ? <Component {...props} /> : onRedirecting();
  });
};
