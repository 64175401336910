import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../components";
import { useStore } from "../stores";

export const Verification = observer(() => {
  const { reservationId } = useParams();
  const { reservationStore } = useStore();
  const {
    reservation,
    isVerificationLoading,
    isValid,
    isAlreadyScanned,
    notFound,
    isUnauthorized,
  } = reservationStore;

  // console.log(isAlreadyScanned, isValid, isVerificationLoading);

  useEffect(() => {
    if (reservationId) reservationStore.verifyReservation(reservationId);
  }, []);

  const renderReservationInformation = () => (
    <div className="mt-4 flex flex-col">
      <Typography variant="body1">
        Aktivität: {reservation?.activitySnapshot?.name}
      </Typography>
      <Typography variant="body1">
        Datum & Uhrzeit:{" "}
        {moment(reservation?.activitySnapshot?.slot.start).format(
          "DD.MM.YYYY HH:mm"
        )}
      </Typography>
      <Typography variant="body1">
        Verifiziert am:{" "}
        {moment(reservation?.scannedAt).format("DD.MM.YYYY HH:mm")}
      </Typography>
    </div>
  );

  // console.log("GOT RESERVATION: ", reservation);
  return (
    <div className="flex h-full min-h-0 ">
      {isVerificationLoading && <Loading />}
      {isValid && (
        <div className="flex flex-1 justify-center flex-col items-center bg-green-500">
          <Typography variant="h1" align="center">
            Reservierung verifiziert
          </Typography>
          {renderReservationInformation()}
        </div>
      )}
      {isAlreadyScanned && (
        <div className="flex flex-1 justify-center flex-col items-center bg-red-500">
          <Typography variant="h1" align="center">
            Reservierung bereits verifiziert
          </Typography>
          {renderReservationInformation()}
        </div>
      )}
      {notFound && (
        <div className="flex flex-1 justify-center items-center bg-orange-500">
          <Typography variant="h1" align="center">
            Reservierung nicht gefunden
          </Typography>
        </div>
      )}
      {isUnauthorized && (
        <div className="flex flex-1 justify-center items-center bg-white-500">
          <Typography variant="h1" align="center">
            Unauthorized
          </Typography>
        </div>
      )}
    </div>
  );
});
