import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../stores";

export const Callback = observer(() => {
  const {
    authStore: { returnTo },
  } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (returnTo) {
      navigate(returnTo);
    }
  }, [returnTo]);
  return <div className="flex"></div>;
});
