import { CircularProgress } from "@mui/material";
import { ComponentType } from "react";
import { createBrowserRouter } from "react-router-dom";
import { withAuthenticationRequired } from "../hooks";
import {
  Callback,
  CancelReservation,
  CancelReservationSuccess,
  Cart,
  Dashboard,
  ReservationSuccess,
  Reservations,
  Root,
  Unauthorized,
  Verification,
} from "../pages";

const ProtectedRoute = ({
  component,
  options,
}: {
  component: ComponentType;
  options?: any;
}) => {
  const Component = withAuthenticationRequired(component, {
    ...options,
    onRedirecting: () => (
      <div className=" flex flex-1 justify-center items-center ">
        <CircularProgress />
      </div>
    ),
  });
  return <Component />;
};

export enum ROUTES {
  ROOT = "/",
  DASHBOARD = "/",
  CART = "/cart",
  CANCEL_RESERVATION = "/cancel/:reservationId",
  RESERVATION_SUCCESS = "/success",
  CANCEL_RESERVATION_SUCCESS = "/cancel/success",
  CALLBACK = "/callback",
  RESERVATIONS = "/reservations",
  UNAUTHORIZED = "/unauthorized",
  VERIFY = "/verify/:reservationId",
}

export const router = createBrowserRouter([
  {
    path: ROUTES.DASHBOARD,
    element: <Root />,
    children: [
      { path: ROUTES.DASHBOARD, element: <Dashboard /> },
      {
        path: ROUTES.CART,
        element: <ProtectedRoute component={Cart} />,
      },
      {
        path: ROUTES.CANCEL_RESERVATION,
        element: <ProtectedRoute component={CancelReservation} />,
      },
      {
        path: ROUTES.CANCEL_RESERVATION_SUCCESS,
        element: <ProtectedRoute component={CancelReservationSuccess} />,
      },

      {
        path: ROUTES.RESERVATION_SUCCESS,
        element: <ProtectedRoute component={ReservationSuccess} />,
      },
      { path: ROUTES.CALLBACK, element: <Callback /> },
      {
        path: ROUTES.RESERVATIONS,
        element: (
          <ProtectedRoute
            options={{ role: "scanner" }}
            component={Reservations}
          />
        ),
      },
      { path: ROUTES.UNAUTHORIZED, element: <Unauthorized /> },
    ],
  },
  {
    path: ROUTES.VERIFY,
    element: (
      <ProtectedRoute options={{ role: "scanner" }} component={Verification} />
    ),
  },
]);
