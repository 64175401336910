import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/react";
import moment from "moment-timezone";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ServiceContext, StoreContext, rootStore } from "./stores";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { deDE } from "@mui/x-date-pickers/locales";
import "moment/locale/de";
import { RouterProvider } from "react-router-dom";
import "./i18n";
import { router } from "./routing";
import theme from "./theme";

// moment.tz.setDefault("Europe/Berlin");

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: window._env_.SENTRY_DSN,
  environment: window._env_.SENTRY_ENVIRONMENT,

  // integrations: [new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <React.Suspense fallback="">
      <StoreContext.Provider value={rootStore.stores}>
        <ServiceContext.Provider value={rootStore.services}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                dateLibInstance={moment}
                localeText={
                  deDE.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
                dateAdapter={AdapterMoment}
                // adapterLocale="de"
                // localeText={
                //   deDE.components.MuiLocalizationProvider.defaultProps.localeText
                // }
              >
                <CssBaseline />
                <RouterProvider router={router} />

                {/* <Loading /> */}
                {/* <AuthModule /> */}
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ServiceContext.Provider>
      </StoreContext.Provider>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
