import { makeAutoObservable } from "mobx";
import { RootStore } from ".";
import {
  VivenuCheckout,
  VivenuCheckoutWithoutAdmission,
  vivenuCheckoutSchema,
  vivenuCheckoutWithoutAdmissionSchema,
} from "../models";
import { ExtraTicket } from "../types";

export class AdmissionStore {
  rootStore: RootStore;
  _ticketModalOpen = false;
  checkoutUrl = "";
  reservationGroupId: string | undefined = undefined;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false }, { autoBind: true });
    this.rootStore = rootStore;
  }

  async createCheckout(
    reservationGroupId: string,
    date: string,
    ticketAmount: number,
    extraTickets: ExtraTicket[] = []
  ) {
    this.reservationGroupId = reservationGroupId;
    const { data } = await this.rootStore.services.api.postCheckout(
      reservationGroupId,
      date,
      ticketAmount,
      extraTickets
    );
    const checkout = vivenuCheckoutSchema.parse(data);
    this.setCheckoutUrl(checkout);
    return checkout;
  }

  async createCheckoutWithoutAdminssion(
    reservationGroupId: string,
    extraTickets: ExtraTicket[]
  ) {
    this.reservationGroupId = reservationGroupId;
    const { data } =
      await this.rootStore.services.api.postCheckoutWithoutAdmission(
        reservationGroupId,
        extraTickets
      );
    data.eventId = extraTickets[0].eventId;
    const checkoutWithoutAdmission =
      vivenuCheckoutWithoutAdmissionSchema.parse(data);
    this.setCheckoutUrl(checkoutWithoutAdmission);
    return checkoutWithoutAdmission;
  }

  setTicketModalOpen(value: boolean) {
    this._ticketModalOpen = value;
  }

  setCheckoutUrl(checkout: VivenuCheckout | VivenuCheckoutWithoutAdmission) {
    let eventId = "event" in checkout ? checkout.event._id : checkout.eventId;
    this.checkoutUrl = `${window._env_.VIVENU_BASE_URL}/checkout/${eventId}?checkoutId=${checkout._id}&secret=${checkout.secret}&useEmbed=true`;
  }

  get ticketModalOpen() {
    return this._ticketModalOpen;
  }
}

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}
