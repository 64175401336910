import { Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../stores";

export const ReservationSuccess = observer(() => {
  const navigate = useNavigate();

  const { activityStore } = useStore();

  useEffect(() => {
    // Clean up stuff from the successful reservation
    activityStore._selectedActivities.clear();
  }, []);

  return (
    <div className="flex flex-1 flex-col justify-center items-center p-4">
      <Typography variant="h1" className="text-center">
        Wir bestätigen deine Buchung!
      </Typography>
      <Typography variant="body1" className="text-center my-4">
        Alle weiteren Details zu deiner Buchung erhältst du, bzw. erhalten alle
        angegebenen Personen, via E-Mail.
      </Typography>
      <Button variant="outlined" onClick={() => navigate("/")}>
        Zurück zur Startseite
      </Button>
    </div>
  );
});
