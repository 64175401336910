import { makeAutoObservable, observable, runInAction } from "mobx";
import { RootStore } from ".";
import { FilterParams, Reservation } from "../types";

export class ReservationStore {
  rootStore;
  isVerificationLoading = false;
  isUnauthorized = false;
  isValid = false;
  isAlreadyScanned = false;
  notFound = false;
  reservationsPaginated = observable.map<number, Reservation[]>([]);
  reservation: Reservation | null = null;
  _reservations = observable.map<string, Reservation>([]);
  isReservationsLoading = false;
  totalReservations = 0;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false }, { autoBind: true });
    this.rootStore = rootStore;
  }

  async fetchReservation(reservationId: string) {
    const { data } = await this.rootStore.services.api.getReservation(
      reservationId
    );
    runInAction(() => {
      this.reservation = data;
    });
  }

  async fetchReservations({
    page = 0,
    skip = 0,
    filter,
  }: {
    page?: number;
    skip?: number;
    filter?: FilterParams;
  } = {}) {
    this.isReservationsLoading = true;
    const { data } = await this.rootStore.services.api.getReservations({
      skip,
      filter,
    });
    runInAction(() => {
      this.totalReservations = data.total;
      this.isReservationsLoading = false;
      this.reservationsPaginated.set(page, data.data);
    });
  }

  async updateReservation(reservationId: string, update: Partial<Reservation>) {
    const { data, status } =
      await this.rootStore.services.api.deleteReservation(reservationId);

    if (status !== 200) return status;
    runInAction(() => {
      this.reservation = data;
    });
    return status;
  }

  async getReservationGroup(reservationGroupId: string) {
    return this.rootStore.services.api.getReservationGroup(reservationGroupId);
  }

  async verifyReservation(reservationId: string) {
    // reset state
    runInAction(() => {
      this.isVerificationLoading = true;
      this.reservation = null;
      this.isValid = false;
      this.isAlreadyScanned = false;
      this.notFound = false;
      this.isUnauthorized = false;
    });

    const { data, status } =
      await this.rootStore.services.api.verifyReservation(reservationId);

    let isValid = this.isValid;
    let isAlreadyScanned = this.isAlreadyScanned;
    let notFound = this.notFound;
    let isUnauthorized = this.isUnauthorized;

    if (status === 200) {
      isValid = true;
    } else if (status === 409) {
      isAlreadyScanned = true;
    } else if (status === 405) {
      isUnauthorized = true;
    } else {
      notFound = true;
    }

    runInAction(() => {
      this.isVerificationLoading = false;
      this.isValid = isValid;
      this.isAlreadyScanned = isAlreadyScanned;
      this.notFound = notFound;
      this.reservation = data;
      this.isUnauthorized = isUnauthorized;
    });
  }

  get reservations() {
    return Array.from(this._reservations.values());
  }

  getReservations(page: number) {
    return this.reservationsPaginated.get(page) || [];
  }
}
