import { makeAutoObservable } from "mobx";
import { RootStore } from ".";

export class UserStore {
  rootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false }, { autoBind: true });
    this.rootStore = rootStore;
  }
}
