import { deDE as coreDeDE } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { deDE as dataGridDeDE } from "@mui/x-data-grid";
import { deDE } from "@mui/x-date-pickers/locales";
import AvenirNextBold from "./assets/fonts/AvenirNext-Bold.ttf";
import AvenirNextRegular from "./assets/fonts/AvenirNext-Regular.ttf";

const rootElement = document.getElementById("root");

// export const colors = [
//   "rgb(255, 69, 58)",
//   "rgb(255, 159, 10)",
//   "rgb(255, 214, 10)",
//   "rgb(50, 215, 75)",
//   "rgb(102, 212, 207)",
//   "rgb(106, 196, 220)",
//   "rgb(90, 200, 245)",
//   "rgb(10, 132, 255)",
//   "rgb(94, 92, 230)",
//   "rgb(191, 90, 242)",
//   "rgb(255, 55, 95)",
//   "rgb(172, 142, 104)",
//   "rgb(152, 152, 157)",
// ];

// export const colors = [
//   "rgb(215, 0, 21)",
//   "rgb(201, 52, 0)",
//   "rgb(178, 80, 0)",
//   "rgb(36, 138, 61)",
//   "rgb(12, 129, 123)",
//   "rgb(0, 130, 153)",
//   "rgb(0, 113, 164)",
//   "rgb(0, 64, 221)",
//   "rgb(54, 52, 163)",
//   "rgb(137, 68, 171)",
//   "rgb(211, 15, 69)",
//   "rgb(127, 101, 69)",
//   "rgb(152, 152, 157)",
// ];

export const colors = [
  "rgb(255, 59, 48)",
  "rgb(255, 149, 0)",
  "rgb(255, 204, 0)",
  "rgb(52, 199, 89)",
  "rgb(0, 199, 190)",
  "rgb(48, 176, 199)",
  "rgb(50, 173, 230)",
  "rgb(0, 122, 255)",
  "rgb(88, 86, 214)",
  "rgb(175, 82, 222)",
  "rgb(255, 45, 85)",
  "rgb(162, 132, 94)",
  "rgb(255, 59, 48)",
];

// A custom theme for this app

export let theme = createTheme(
  {
    palette: {
      primary: {
        contrastText: "white",
        main: "#CC99CC",
      },
      secondary: {
        light: "#cb3d64",
        main: "#BF0D3E",
        dark: "#85092b",
        contrastText: "white",
      },
    },
    typography: {
      fontFamily: "AvenirNext",
      h1: {
        fontFamily: "AvenirNext",
        fontSize: 38,
        color: "#333",
        fontWeight: "600",
      },
      h2: {
        fontFamily: "AvenirNext",
        fontSize: 26,
        color: "#333",
        fontWeight: "600",
      },
      subtitle1: {
        fontFamily: "AvenirNext",
        fontSize: 20,
        lineHeight: "150%",
        color: "#333",
        fontWeight: "600",
      },
      subtitle2: {
        fontWeight: "600",
        fontFamily: "AvenirNext",
      },
      body1: {
        fontFamily: "AvenirNext",
      },
      button: {
        fontFamily: "AvenirNext",
        fontWeight: "600",
      },
    },
  },
  deDE, // x-date-pickers translations
  dataGridDeDE, // x-data-grid translations
  coreDeDE // core translations
);

theme = createTheme(
  theme,
  {
    components: {
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiModal: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'AvenirNext';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('AvenirNextRegular'), local('AvenirNext-Regular'), url(${AvenirNextRegular}) format('truetype');
        }

        @font-face {
          font-family: 'AvenirNext';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('AvenirNextBold'), local('AvenirNextBold'), url(${AvenirNextBold}) format('truetype');
        }
      `,
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {},
        styleOverrides: {
          root: {
            "& p ": {
              fontFamily: "AvenirNext",
              fontWeight: "600",
            },
          },
          outlined: {
            // border: "1px solid #B0B0B0",
            borderWidth: "2px",
            color: "#000",
            borderRadius: 100,

            "&:hover": {
              borderWidth: "2px",
            },
          },
        },
        // defaultProps: {
        //   disableElevation: true,
        //   // color: theme.palette.secondary.main,
        // },
        // variants: [
        //   {
        //     props: { variant: "outlined" },
        //     style: {
        //       borderWidth: "2px",
        //     },
        //   },
        // ],
        // styleOverrides: {
        //   root: {
        //     border: "1px solid #B0B0B0",
        //   },
        // },
      },
      MuiTextField: {
        defaultProps: {
          InputProps: {
            disableUnderline: true,
          },
        },
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              borderRadius: 8,
            },
            // border: "1px solid #B0B0B0",
            overflow: "hidden",
            borderRadius: 8,

            // backgroundColor: "#fffff",

            // transition: theme.transitions.create([
            //   "border-color",
            //   "background-color",
            //   "box-shadow",
            // ]),

            "&&& label": {
              fontFamily: "AvenirNext",
              color: "#000",
              fontWeight: "600",
            },
            "&&& label.Mui-focused": {
              color: "#7E7E7E",
            },
            // "&:hover": {
            //   backgroundColor: "red",
            // },
            // "& .MuiFilledInput-underline:hover": {
            //   borderBottom: "none",
            // },
            // "& .MuiInputBase-input": {
            //   backgroundColor: "white",
            //   fontFamily: "AvenirNext",
            //   color: "#000",
            //   fontWeight: "600",
            //   // borderBottom: "none",
            // },

            // "&&& .MuiFilledInput-underline:before": {
            //   borderBottom: "none",
            // },
            // "&&& .MuiFilledInput-underline:after": {
            //   // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,

            //   borderBottom: "none",
            // },
          },
          // '&:hover': {
          //   backgroundColor: 'transparent',
          // },
          // '&.Mui-focused': {
          //   backgroundColor: 'transparent',
          //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
          //   borderColor: theme.palette.primary.main,
          // },
        },
      },
    },
  },
  deDE, // x-date-pickers translations
  dataGridDeDE, // x-data-grid translations
  coreDeDE // core translations
);

export default theme;
