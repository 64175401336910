import {
  AccountCircleOutlined,
  CloseOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Badge,
  ClickAwayListener,
  Collapse,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import { CheckoutModal } from "../components/CheckoutModal";
import { useStore } from "../stores";

export const Root = observer(() => {
  const { authStore, activityStore } = useStore();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(true);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className="flex h-full min-h-0 flex-col">
      <Collapse in={alertOpen}>
        <Alert
          severity="warning"
          className="px-4 md:px-8"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseOutlined fontSize="inherit" />
            </IconButton>
          }
        >
          Zur Zeit ist eine eingeschränkte Auswahl der vor Ort verfügbaren
          Aktivitäten zur Reservierung verfügbar. Wir werden das Angebot nach
          und nach erweitern. Schaue also später nochmal vorbei.
        </Alert>
      </Collapse>
      <div className="flex h-full min-h-0 justify-center">
        <div className="max-w-screen-2xl  h-full min-h-0  flex flex-1 flex-col">
          <div className="flex relative w-full pt-6 pb-6 px-6 justify-center align-middle">
            <a href="/">
              <img src={Logo} className="block" alt="Logo" width={100} />
            </a>

            <div className="absolute right-0 mr-6 flex gap-x-3">
              <IconButton disabled={activityStore.hasSelectedActivities}>
                <Badge
                  badgeContent={
                    activityStore.selectedActivitiesAsTimedEvents.length
                  }
                  color="secondary"
                >
                  <ShoppingCartOutlined
                    onClick={() => navigate("/cart")}
                    className={`${
                      !activityStore.hasSelectedActivities
                        ? "text-black"
                        : "inherit"
                    }`}
                  />
                </Badge>
              </IconButton>

              {authStore.token && (
                <>
                  <IconButton
                    ref={anchorRef}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    // disabled={activityStore.hasSelectedActivities}
                    // className="absolute right-0 mr-6"
                  >
                    <AccountCircleOutlined className="text-black" />
                  </IconButton>

                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              // onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={() =>
                                  authStore.logout(window.location.origin + "/")
                                }
                              >
                                Abmelden
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </div>
          </div>
          <div className="h-full min-h-0 flex flex-1 flex-col px-4 pb-4 md:px-8 md:pb-8">
            <Outlet />
          </div>
        </div>
      </div>
      <CheckoutModal />
    </div>
  );
});
