export function hexToRgb(hex: string) {
  // Remove the '#' character if present
  hex = hex.replace(/^#/, "");

  // Convert 3-digit hex to 6 digits
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Parse the hex value and convert it to an RGB object
  const intValue = parseInt(hex, 16);
  const r = (intValue >> 16) & 255;
  const g = (intValue >> 8) & 255;
  const b = intValue & 255;

  return r + "," + g + "," + b;
}

export function convertToRgba(rgbString: string, opacity: number) {
  let rgbValues = rgbString.match(/\d+/g)!.map(Number); // Extract the RGB values
  return `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${opacity})`;
}
