import { LoadingButton } from "@mui/lab";
import { Alert, Skeleton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../routing";
import { useStore } from "../stores";

export const CancelReservation = observer(() => {
  const { reservationId } = useParams();
  const { reservationStore } = useStore();
  const [cancellationLoading, setCancellationLoading] = useState(false);
  const [cancellationError, setCancellationError] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReservation = async () => {
      if (reservationId) {
        await reservationStore.fetchReservation(reservationId);
        setLoading(false);
      }
    };
    fetchReservation();
  }, [reservationId]);

  if (!reservationId) return null;

  // console.log(reservationStore.reservation);

  if (loading)
    return (
      <div className="flex flex-1 flex-col  items-center">
        <Skeleton variant="text" width={300} height={60} />
        <Skeleton variant="text" width={300} />
        <Skeleton variant="text" width={300} />
        <Skeleton variant="text" width={300} />
        <Skeleton variant="rounded" className="mt-4" width={200} height={60} />
      </div>
    );

  if (!reservationStore.reservation && !loading)
    return (
      <div className="flex justify-center">
        <Alert severity="error" className="flex flex-1 max-w-xl">
          Reservierung nicht gefunden.
        </Alert>
      </div>
    );

  if (!loading && reservationStore.reservation?.status === "CANCELLED") {
    return (
      <div className="flex justify-center">
        <Alert severity="error" className="flex flex-1 max-w-xl">
          Diese Reservierung wurde bereits storniert.
        </Alert>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center">
      <Typography variant="h1">Stornieren</Typography>
      <div className="my-4">
        <div className="flex">
          <Typography variant="body1" className="font-bold w-24">
            Aktivität:
          </Typography>
          <Typography variant="body1">
            {reservationStore.reservation?.activitySnapshot.name}
          </Typography>
        </div>
        <div className="flex">
          <Typography variant="body1" className="font-bold w-24">
            Besucher:
          </Typography>
          <Typography variant="body1">
            {reservationStore.reservation?.visitorName}
          </Typography>
        </div>
        <div className="flex">
          <Typography variant="body1" className="font-bold w-24">
            Wann:
          </Typography>
          <Typography variant="body1">
            {moment(
              reservationStore.reservation?.activitySnapshot.slot.start
            ).format("HH:mm")}{" "}
            {moment(reservationStore.reservation?.selectedDate).format(
              "DD.MM.YYYY"
            )}
          </Typography>
        </div>
      </div>

      {!!cancellationError && (
        <Alert severity="error" className="flex flex-1 max-w-xl mb-4">
          {cancellationError}
        </Alert>
      )}

      <LoadingButton
        variant="contained"
        color="secondary"
        loading={cancellationLoading}
        disabled={cancellationLoading || !!cancellationError}
        onClick={async () => {
          setCancellationLoading(true);
          const status = await reservationStore.updateReservation(
            reservationId,
            {
              status: "CANCELLED",
            }
          );
          setCancellationLoading(false);
          if (status === 200) {
            navigate(ROUTES.CANCEL_RESERVATION_SUCCESS);
          }
          if (status === 400) {
            setCancellationError(
              "Eine Reservierung kann nur bis 24h bevor die Aktivität stattfindet storniert werden."
            );
          }
        }}
      >
        Jetzt stornieren
      </LoadingButton>
    </div>
  );
});
