import {
  AddCircleOutline,
  Delete,
  DoNotDisturbOnOutlined,
  SearchRounded,
} from "@mui/icons-material";
import { Button, IconButton, Input, Popover, Typography } from "@mui/material";
import {
  BaseSingleInputFieldProps,
  DatePicker,
  DatePickerProps,
  DateValidationError,
  FieldSection,
  UseDateFieldProps,
} from "@mui/x-date-pickers";
import { observer } from "mobx-react-lite";
import moment, { Moment } from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../stores";

export const Toolbar = observer(() => {
  const { activityStore } = useStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const { selectedDate } = activityStore;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  const open = Boolean(anchorEl);

  const updateSelectedDate = (date: Moment) => {
    activityStore.setSelectedDate(date);
    handleSearch();
  };

  const handleSearch = () => {
    activityStore._availableActivities.clear();
    activityStore._selectedActivities.clear();
    activityStore.searchActivities();
  };

  const id = open ? "simple-popover" : undefined;
  return (
    <div className="flex border border-slate-300 rounded-full bg-gray-100 max-w-96 justify-between items-stretch flex-row pl-6 mt-6">
      <ButtonDatePicker
        defaultValue={selectedDate}
        onChange={(newValue) => newValue && updateSelectedDate(newValue)}
        value={selectedDate}
        className="flex flex-col flex-auto justify-center text-black normal-case"
      >
        <Typography variant="subtitle2" className="">
          Datum
        </Typography>
        <Typography variant="body1">
          {selectedDate.format("DD.MM.YYYY")}
        </Typography>
      </ButtonDatePicker>
      {/* <ButtonDatePicker className="flex flex-col flex-auto justify-center text-black normal-case">
      <Typography variant="subtitle2" className="">
        ab Uhrzeit
      </Typography>
      <Typography variant="body1">
        {selectedTime || "auswählen"}
      </Typography>
    </ButtonDatePicker> */}
      <Button
        className="flex flex-col flex-auto justify-center text-black normal-case"
        onClick={handleClick}
        area-describedby={id}
        size="small"
      >
        <Typography variant="subtitle2" className="">
          Wer
        </Typography>
        <Typography variant="body1">
          {activityStore.group.length}{" "}
          {t("persons", { count: activityStore.group.length })}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="p-4">
          <div className="flex items-center  flex-row">
            <Typography variant="subtitle2" className="mr-1">
              Personen
            </Typography>
            <IconButton
              onClick={() =>
                activityStore.removeGroupMember(activityStore.group.at(-1)!.id)
              }
            >
              <DoNotDisturbOnOutlined />
            </IconButton>
            <Typography className="mx-1">
              {activityStore.group.length}
            </Typography>
            <IconButton onClick={activityStore.addGroupMember}>
              <AddCircleOutline />
            </IconButton>
          </div>
          <div className="flex flex-col gap-1.5">
            {activityStore.group.map((member) => {
              return (
                <div>
                  <Input
                    placeholder="Name eintragen"
                    value={member.name}
                    onChange={(e) =>
                      activityStore.changeGroupMemberName(
                        member.id,
                        e.target.value
                      )
                    }
                  />
                  <IconButton
                    onClick={() => activityStore.removeGroupMember(member.id)}
                  >
                    <Delete />
                  </IconButton>
                </div>
              );
            })}
          </div>
        </div>
      </Popover>

      <Button
        variant="contained"
        startIcon={<SearchRounded />}
        className=" flex text-white rounded-full px-6 bg-[#00828C]"
        onClick={handleSearch}
        size="small"
      >
        Suche
      </Button>
    </div>
  );
});

interface ButtonFieldProps
  extends UseDateFieldProps<Moment>,
    BaseSingleInputFieldProps<
      Moment | null,
      Moment,
      FieldSection,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
  className?: string;
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
    children,
    className,
  } = props;

  return (
    <Button
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      className={className}
    >
      {children}
    </Button>
  );
}

interface ButtonDatePickerProps
  extends Omit<DatePickerProps<Moment>, "open" | "onOpen" | "onClose"> {
  children: React.ReactNode;
  className?: string;
}

export function ButtonDatePicker(props: ButtonDatePickerProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      // @ts-ignore
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{
        field: {
          setOpen,
          children: props.children,
          className: props.className,
        } as any,
      }}
      {...props}
      open={open}
      minDate={moment()}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      timezone="Europe/Paris"
    />
  );
}
