import { observer } from "mobx-react-lite";

export const DebugCheckout = observer(() => {
  return (
    <div className="flex flex-1 items-center justify-center">
      <a
        className="vivenu-btn yourStylingClass"
        href="https://vivenu.dev/checkout/649ac70041d502905040f873?useEmbed=true"
      >
        Buy Tickets
      </a>
    </div>
  );
});
