import { z } from "zod";

export const vivenuEventSchema = z.object({
  _id: z.string(),
  url: z.string(),
  name: z.string(),
  image: z.string(),
  sellerId: z.string(),
  start: z.string(),
  end: z.string(),
  locationName: z.string(),
  locationStreet: z.string(),
  locationCity: z.string(),
  locationPostal: z.string(),
  locationCountry: z.string(),
  currency: z.string(),
  timezone: z.string(),
  accountSettings: z.object({}),
  eventType: z.string(),
  childEvents: z.array(z.object({})),
  startingPrice: z.number(),
  showTimeRangeInListing: z.boolean(),
  styleOptions: z.object({}),
  saleStatus: z.string(),
});

export const vivenuCheckoutSchema = z.object({
  status: z.string(),
  type: z.string(),
  sellerId: z.string(),
  realPrice: z.number(),
  currency: z.string(),
  secret: z.string(),
  preferredLanguage: z.string(),
  expiresAt: z.string(),
  _id: z.string(),
  event: vivenuEventSchema,
  items: z.array(z.object({})),
  userAgent: z.object({}),
  ipLookup: z.object({}),
  channel: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  __v: z.number(),
});

export const vivenuCheckoutWithoutAdmissionSchema = z.object({
  status: z.string(),
  type: z.string(),
  sellerId: z.string(),
  realPrice: z.number(),
  currency: z.string(),
  secret: z.string(),
  preferredLanguage: z.string(),
  expiresAt: z.string(),
  _id: z.string(),
  eventId: z.string(),
  items: z.array(z.object({})),
  userAgent: z.object({}),
  ipLookup: z.object({}),
  channel: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  __v: z.number(),
});

export type VivenuCheckout = z.infer<typeof vivenuCheckoutSchema>;
export type VivenuCheckoutWithoutAdmission = z.infer<
  typeof vivenuCheckoutWithoutAdmissionSchema
>;
