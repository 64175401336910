import { Button, Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  ActivitiesFilter,
  ActivitiesList,
  Calendar,
  Toolbar,
} from "../components";

import { ShoppingCartOutlined } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useStore } from "../stores";

export const Dashboard = observer(() => {
  const { activityStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    activityStore.fetchActivities();
  }, []);

  return (
    <>
      <div className="flex flex-1 flex-col min-h-0 overflow-auto">
        <div className="flex justify-center flex-col items-center mb-8">
          <Typography variant="h1" className="text-center">
            Ein Tag nach deinen Wünschen
          </Typography>
          <Typography variant="body1" className="text-center">
            Checke einfach die Verfügbarkeiten für den entsprechenden Tag und
            füge die Aktivität deinem Warenkorb hinzu.
          </Typography>

          {/* Toolbar */}
          <Toolbar />
        </div>
        <div className="flex w-full align-middle self-center md:gap-8 min-h-0">
          <div className="flex w-full md:w-1/2 md:overflow-y-auto flex-col">
            <div className="flex flex-row justify-between items-center flex-wrap">
              <Typography variant="subtitle2">
                Zeige Ergebnisse für:{" "}
                {activityStore.selectedDate.format("DD.MM.YYYY")}
              </Typography>
              <ActivitiesFilter />
            </div>
            {activityStore.availableActivities.length > 0 && <ActivitiesList />}
            {activityStore.isLoadingActivities &&
              activityStore.availableActivities.length === 0 && (
                <div
                  className="flex gap-3.5 flex-col"
                  id="activities-loading-indicator"
                >
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={260}
                    animation="wave"
                  />
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={260}
                    animation="wave"
                  />
                </div>
              )}
            {!activityStore.isLoadingActivities &&
              activityStore.availableActivities.length === 0 && (
                <div className="flex flex-row items-center justify-center h-full">
                  <Typography variant="body1">
                    Keine Aktivitäten verfügbar für diesen Tag.
                  </Typography>
                </div>
              )}
          </div>
          <div className="md:flex  md:w-1/2 flex-col items-center hidden">
            <Calendar events={activityStore.selectedActivitiesAsTimedEvents} />
            <div className="flex md:w-full justify-end">
              <Button
                variant="outlined"
                size="large"
                disabled={activityStore.hasSelectedActivities}
                startIcon={<ShoppingCartOutlined />}
                onClick={() => navigate("/cart")}
              >
                <Typography>Warenkorb ansehen</Typography>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <BottomNavigation className="md:hidden z-[2000]" showLabels>
        <BottomNavigationAction
          label="Aktivitäten"
          onClick={() => setShowScheduleMobile(false)}
          icon={
            <LocalActivity
              color={!showScheduleMobile ? "primary" : "inherit"}
            />
          }
        />
        <BottomNavigationAction
          label="Zeitplan"
          onClick={() => setShowScheduleMobile(!showScheduleMobile)}
          icon={
            <CalendarViewDay
              color={showScheduleMobile ? "primary" : "inherit"}
            />
          }
        />
        <BottomNavigationAction
          label="Warenkorb"
          onClick={() => navigate("/cart")}
          icon={<ShoppingCartOutlined />}
        />
      </BottomNavigation>
      <Modal
        open={showScheduleMobile}
        onClose={() => setShowScheduleMobile(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute bg-white bottom-[56px] left-0 right-0 px-4 py-6 rounded-t-lg">
          <Calendar events={activityStore.selectedActivitiesAsTimedEvents} />
        </div>
      </Modal> */}
    </>
  );
});
