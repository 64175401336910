import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

export const Unauthorized = observer(() => {
  return (
    <div className="flex h-full min-h-0 justify-center items-center">
      <Typography variant="h1">Unauthorized</Typography>
    </div>
  );
});
