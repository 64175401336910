import { CloseRounded } from "@mui/icons-material";
import { IconButton, Modal } from "@mui/material";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../stores";

export const CheckoutModal = observer(() => {
  const {
    admissionStore: {
      setTicketModalOpen,
      ticketModalOpen,
      checkoutUrl,
      reservationGroupId,
    },
    reservationStore: { getReservationGroup },
  } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleModalClose = async () => {
    setLoading(true);
    if (!reservationGroupId)
      throw new Error("reservationGroupId is not defined");
    const { data, status } = await getReservationGroup(reservationGroupId);
    Sentry.captureMessage(`Reservation Group Status: ${status}`, {
      extra: {
        reservationGroupId,
        data,
      },
    });

    setLoading(false);

    if (
      data.reservations.every(
        (reservation: any) => reservation.status === "CONFIRMED"
      )
    ) {
      setTicketModalOpen(false);
      navigate("/success");
    } else {
      setTicketModalOpen(false);
    }
    // setTicketModalOpen(false);
    // navigate("/success");
  };
  return (
    <Modal
      open={!!ticketModalOpen}
      onClose={() => setTicketModalOpen(false)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className="flex justify-center items-center p-4"
    >
      <div className="w-full h-full md:w-3/4 md:h-3/4 bg-white border-0 rounded-lg">
        <div className="flex justify-end">
          <IconButton onClick={handleModalClose}>
            <CloseRounded />
          </IconButton>
        </div>
        <iframe
          height={"100%"}
          width={"100%"}
          className="border-0 rounded-lg"
          src={checkoutUrl}
        />
      </div>
    </Modal>
  );
});
