import Keycloak from "keycloak-js";
import { makeAutoObservable, runInAction } from "mobx";
import { RootStore } from ".";

const minValidity = 30;

export class AuthStore {
  rootStore: RootStore;
  client: Keycloak;
  isReady = false;
  token: string | undefined;
  returnTo: string | undefined;

  constructor(parent: RootStore) {
    makeAutoObservable(this, { rootStore: false }, { autoBind: true });
    this.rootStore = parent;
    this.client = new Keycloak({
      url: window._env_.AUTH_URI,
      realm: "master",
      clientId: "happyfranky",
    });

    this.initKeycloak();
  }

  async initKeycloak() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      console.log("We are in development mode!");
      //@ts-ignore
      window.keycloak = this.client;
    }

    await this.client.init({
      enableLogging: true,
      checkLoginIframe: false,
      onLoad: "check-sso",
    });

    runInAction(() => {
      this.token = this.client.token;
      this.isReady = true;
    });

    if (this.client.authenticated) {
      this.handleTokenRefresh();
    }

    const returnTo = localStorage.getItem("path");
    // localStorage.removeItem("path");

    if (returnTo) {
      this.returnTo = returnTo;
    } else {
      this.returnTo = "/";
    }
  }

  setToken(token: string | undefined) {
    this.token = token;
  }

  async logout(redirectUri?: string) {
    this.rootStore.stores.activityStore.removeCartFromLocalStorage();
    await this.client.logout({
      redirectUri: redirectUri || window._env_.REDIRECT_URI,
    });
  }

  async login() {
    await this.client.login({
      redirectUri: window._env_.REDIRECT_URI,
    });
  }

  handleTokenRefresh = () => {
    setInterval(async () => {
      try {
        const refreshed = await this.client.updateToken(minValidity);
        if (refreshed) {
          // console.log("Token refreshed");
          this.setToken(this.client.token);
        } else {
          // console.log("Token not refreshed");
          this.setToken(this.client.token);
        }
      } catch (e) {
        console.log("Could not refresh token", e);
        this.client.logout({
          redirectUri: window._env_.REDIRECT_URI,
        });
      }
    }, (minValidity - 1) * 1000);
  };
}
